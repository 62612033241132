(function ($) {


const Init = (() => {

  // settings
  
  
  let website      = {},
      load         = {},
      resize       = {},
      scroll       = {},
      mod          = {},
      scrollOption = {},
      parallax     = {},
      drawer       = {},
      navi         = {},
      form         = {},
      formMethod   = {},
      timer        = {},
      wp           = {},
      iscroll      = {},
      loading      = {},
      totop        = {},
      scrollbar    = {},
      canvas       = {},
      tab          = {},
      favorite     = {},
      accordion    = {},
      megamenu     = {}
      slick        = {};
  
  website = {
    page          : document.body.dataset.page,
    url           : document.URL,
    winWidth      : window.innerWidth,
    winHeight     : window.innerHeight,
    scrollY       : window.pageYOffset,
    breakpoint    : { pc: 1200, tb: 992, sp: 768, ss: 576 },
    ua            : navigator.userAgent.toLowerCase(),
    isDesktop     : true,
    isFirst       : true,
    animationFrame: null,
  
    headTag       : $('.l-header'),
  
    act           : 'is-act',
    actOpen       : 'is-open',
  
    targetAnime : 'js-anime',
    actAnime    : 'is-anime',
    shiftPoint  : 0,
    shiftPc     : 5,
    shiftSp     : 10,
  };
  website.shiftPoint = website.breakpoint.tb;
  
  load = {
    functions: [],
    length   : 0,
  };
  
  resize = {
    functions: [],
    length   : 0,
    // fps      : 60,
    isRunning: false,
    animationFrame: null,
  };
  
  scroll = {
    functions: [],
    length   : 0,
    // fps      : 60,
    isRunning: false,
    animationFrame: null,
  };
  

  //modules
  // サニタイズ関数
  function sanitizing(str){
    var before = new Array('&','"',"'","<",">","\n","\t","\\n");
    var after = new Array('&amp;','&quot;','&rsquo;',"&lt;","&gt;","<br />"," ","<br />");
    for( var i=0; i < before.length; i++ )
      str = str.replace(new RegExp(before[i],'g'), after[i]);
    return str;
  };
  
  // LOAD
  const WinLoad = {
  
    init() {
      window.addEventListener('load', function(e) {
        WinLoad.update();
      });
  
      console.log('!-- load init --!');
    },
  
    add(func) {
      load.functions.push(func);
      load.length = load.functions.length;
    },
  
    remove(func) {
      load.functions.splice(func, 1);
      load.length = load.functions.length;
    },
  
    update() {
      for ( let i = 0; i < load.length; i++ ) {
        let func = load.functions[i];
        func();
      }
    },
  
  }
  
  // RESIZE
  const WinResize = {
  
    init() {
      window.addEventListener('resize', function(e) {
        if ( !resize.isRunning ) {
          resize.isRunning = true;
  
          resize.animationFrame = window.requestAnimationFrame( WinResize.update );
  
          // if ( window.requestAnimationFrame ) {
          //   window.requestAnimationFrame(() => {
          //     WinResize.update();
          //   });
          // } else {
          //   setTimeout(() => {
          //     WinResize.update();
          //   }, 1000/resize.fps);
          // }
        }
      });
  
      console.log('!-- resize init --!');
    },
  
    add(func) {
      resize.functions.push(func);
      resize.length = resize.functions.length;
    },
  
    remove(func) {
      resize.functions.splice(func, 1);
      resize.length = resize.functions.length;
    },
  
    update() {
      website.winWidth  = window.innerWidth;
      website.winHeight = window.innerHeight;
      website.ua        = navigator.userAgent.toLowerCase();
  
      for ( let i = 0; i < resize.length; i++ ) {
        let func = resize.functions[i];
        func();
      }
  
      resize.isRunning = false;
    },
  
  }
  
  // SCROLL
  const WinScroll = {
  
    init() {
      window.addEventListener('scroll', function(e) {
        if ( !scroll.isRunning ) {
          scroll.isRunning = true;
  
          scroll.animationFrame = window.requestAnimationFrame( WinScroll.update );
  
          // if ( window.requestAnimationFrame ) {
          //   window.requestAnimationFrame(() => {
          //     WinScroll.update();
          //   });
          // } else {
          //   setTimeout(() => {
          //     WinScroll.update();
          //   }, 1000/scroll.fps);
          // }
        }
      });
  
      console.log('!-- scroll init --!');
    },
  
    add(func) {
      scroll.functions.push(func);
      scroll.length = scroll.functions.length;
    },
  
    remove(func) {
      scroll.functions.splice(func, 1);
      scroll.length = scroll.functions.length;
    },
  
    update() {
      website.winHeight = window.innerHeight;
      website.scrollY = window.pageYOffset;
  
      for ( let i = 0; i < scroll.length; i++ ) {
        let func = scroll.functions[i];
        func();
      }
  
      scroll.isRunning = false;
    },
  
  }
  
  
  const Modules = {
  
    init() {
      mod = {
        targetSmooth    : 'js-scroll',
        targetTotop     : 'js-totop',
        smoothSpeed     : 600,
  
        targetModal     : 'js-modal',
        targetModalOpen : 'js-modalOpen',
        targetModalClose: 'js-modalClose',
  
        targetAlign     : 'js-align',
        targetAlignList : [],
        alignAddClass   : 'c-alignheight',
  
        targetOfi       : '.js-ofi .ofi',
  
        targetPagenavi  : 'js-pagenavi',
  
        targetDateField : 'js-datepicker',
  
        targetPullTrg   : 'js-pulldown',
        targetPullConts : 'js-pulldown_conts',
  
        confirmTrg      : 'js-confirmMsg',
  
        alertTrg        : 'js-alertMsg',
  
        removeTrg       : 'js-remove',
  
      };
  
      let alignList = document.querySelectorAll('.' + mod.targetAlign);
      mod.targetAlignList = Array.prototype.slice.call(alignList, 0);
  
      this.iosCheck();
      this.deviceCheck();
      this.smoothScroll();
      // this.tabBtn();
      this.modalBtn();
      this.pulldown();
      this.headerFixed();
      this.confirmMessage();
      this.alertMessage();
  
      objectFitImages(mod.targetOfi);
      Stickyfill.add('.js-sticky');
    },
  
    iosCheck() {
      if ( website.ua.indexOf('ipad') > -1 || website.ua.indexOf('iphone') > -1 || (website.ua.indexOf('macintosh') > -1 && 'ontouchend' in document ) ) {
        $('body').addClass('ios');
      } else {
        $('body').removeClass('ios');
      }
    },
  
    deviceCheck() {
      if (
        website.ua.indexOf('iphone') > -1 ||
        website.ua.indexOf('ipod') > -1 ||
        website.ua.indexOf('android') > -1 && website.ua.indexOf('mobile') > -1
      ) {
        website.isDesktop = false;
        $('body').removeClass('pc tablet').addClass('sp');
      } else if (
        website.ua.indexOf('ipad') > -1 ||
        website.ua.indexOf('Android') > -1 ||
        (website.ua.indexOf('macintosh') > -1 && 'ontouchend' in document )
      ) {
        website.isDesktop = false;
        $('body').removeClass('pc sp').addClass('tablet');
      } else {
        website.isDesktop = true;
        $('body').removeClass('tablet sp').addClass('pc');
      }
    },
  
    smoothScroll() {
      $('.' + mod.targetSmooth + ':not([href=""])').on('click', function() {
        let href     = $(this).attr("href"),
            target   = $( (href == "#") ? 'html' : href ),
            position;
        if( website.headTag.css('position') == 'fixed' ){
          shift    = ( (href != "#") ) ? website.headTag.outerHeight() : 0
        }else{
          shift    = 50;
        }
        position = target.offset().top - shift;
        $('body,html').animate({scrollTop: position}, mod.smoothSpeed, 'swing');
        return false;
      });
    },
  
    modalBtn() {
      $('.'+mod.targetModalOpen).on('click',function(){
        $('body').addClass('is-modalOpen');
        let target = $(this).attr('href');
        $(target).fadeIn();
        return false;
      });
      $('.'+mod.targetModalClose).on('click',function(){
        $('body').removeClass('is-modalOpen');
        $(this).closest('.'+mod.targetModal).fadeOut();
        return false;
      });
    },
  
    alignHeight() {
      let alignHeight  = 0;
  
      mod.targetAlignList.forEach((target) => {
        target.style.minHeight = 'auto';
        target.classList.add(mod.alignAddClass);
        alignHeight = ( target.offsetHeight > alignHeight ) ? target.offsetHeight: alignHeight;
        target.classList.remove(mod.alignAddClass);
      });
      mod.targetAlignList.forEach((target) => {
        target.style.minHeight = alignHeight + 'px';
      });
    },
  
    pulldown (){
      if(website.winWidth >= website.shiftPoint){
        $('.'+ mod.targetPullTrg).hover(
          function(){
            $(this).addClass('is-act');
            $(this).children('.'+mod.targetPullConts).addClass('is-act').stop().slideDown();
          },
          function(){
            $(this).removeClass('is-act');
            $(this).children('.'+mod.targetPullConts).removeClass('is-act').stop().slideUp();
          }
        )
      } else{
        $('.'+mod.targetPullTrg).children('a').on('click',function(){
          $(this).parent('.'+mod.targetPullTrg).find('.'+mod.targetPullConts).toggleClass('is-act').stop().slideToggle();
          if(!$(this).parent('.'+mod.targetPullTrg).hasClass('is-act')){
            $(this).parent('.'+mod.targetPullTrg).toggleClass('is-act');
            return false;
          }else{
            $(this).parent('.'+mod.targetPullTrg).toggleClass('is-act');
          }
        });
      }
    },
  
    headerFixed (){
      if( website.headTag.css('position') == 'fixed' && $('.js-wrapper').length){
        $('.js-wrapper').css({
          'padding-top' : website.headTag.outerHeight(),
        });
      }
      if(website.scrollY > 0){
        website.headTag.addClass('is-fixed');
      }else{
        website.headTag.removeClass('is-fixed');
      }
    },
  
    confirmMessage() {
      if( $('.' + mod.confirmTrg ).length ){
        $('.' + mod.confirmTrg ).on('click', function(){
          let message = $(this).attr('data-confirm-message'),
              redirect = $(this).attr('data-url');
          let result = window.confirm(message);
  
          console.log(result);
          if( result ){
            window.location.href = redirect;
          }
        });
      }
    },
  
    alertMessage() {
      if( $('.' + mod.alertTrg ).length ){
        $('.' + mod.alertTrg ).on('click', function(){
          let message = $(this).attr('data-alert-message');
          alert(message);
        });
      }
    },
    removeElement() {
      $('.'+mod.removeTrg).on('click',function(){
        let target = $(this).data('target');
        $(target).remove();
        return false;
      });
    },
  }
  
  const Slick = {
  
    init() {
      this.topKey();
    },
  
    topKey() {
      if($('.js-kv').length){
        let autospeed = 5000;
        $('.js-kv').slick({
          fade: true,
          autoplay: false,
          speed: 500,
          cssEase: 'linear',
          arrows: false,
          dots: false,
          pauseOnFocus: false,
          pauseOnHover: false,
          responsive: [
            {
              // breakpoint: website.breakpoint.ss,
              // settings: {
              //   dots: false,
              // }
  
            },],
        });
        if(!(website.winWidth < website.breakpoint.tb)){
          $('.js-kvTrg').hover(
            function(){
              let idx = $(this).data('target-index');
              $('.js-kv').slick('slickGoTo',idx,false);
            },
            function(){
                $('.js-kv').slick('slickGoTo',0,true);
            }
          );
        }
      }
    },
  
  }
  


  // OFF
  // ./inc/_drawermenu.js
  // ./inc/_animation.js
  // ./inc/_megamenu.js
  // ./inc/_totop.js
  // ./inc/_timer.js
  // ./inc/_accordion.js
  // ./inc/_loading.js
  // ./inc/_pagescroll.js
  // ./inc/_parallax.js
  // ./inc/_wordpress.js
  // ./inc/_form.js
  // ./inc/_favorite.js


  // page
  // ./page/_all.js
  const TopPage = {
  
    init() {
     // $(window).scrollTop(0);
     window.addEventListener('wheel', function(e){
      $('.js-wrapper').addClass('is-act');
    });
  
    window.addEventListener('touchend',function(e){
      $('.js-wrapper').addClass('is-act');
    });
  
    },
    update() {
      console.log('scrolled');
      $('.js-wrapper').addClass('is-act');
    },
  
  }
  
   



  const App = {

    init() {
      this.set();
    },
    set() {
      Modules.init();
      TopPage.init();
      Slick.init();

      WinResize.add( Modules.iosCheck );
      WinResize.add( Modules.deviceCheck );

      WinScroll.add( TopPage.update );

      WinLoad.init();
      WinResize.init();
      WinScroll.init();

      

    },

    // update() {
    //   website.animationFrame = window.requestAnimationFrame( App.update );
    //   website.winWidth       = window.innerWidth;
    //   website.winHeight      = window.innerHeight;
    //   website.scrollY        = window.pageYOffset;

    //   if ( website.isDesktop ) {
    //     PageScroll.smooth();

    //     if ( website.page == 'top' ) {
    //       Parallax.moveImg();
    //     }
    //   }
    // },

  }


  App.init();

})();


})(jQuery);
